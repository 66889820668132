<template>
    <div class="scroll-div flex">
        <!-- <div class="table-operate" style="margin: 0 20px">
            <el-button type="text" icon="el-icon-document" @click="exportInfo()">导出数据</el-button>
        </div> -->
        <div class="flex-center" v-loading="loading">
            <template v-if="error === 'A0004'">
                <ts-error></ts-error>
            </template>
            <template v-else>
                <div :id="nameKey" class="canvas small"></div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['visitId'],
        data() {
            return {
                nameKey: 'overviewTotal',
                error: '',
                loading: false,
                drugsNature: '',
                selectLoading: false,
                selectList: [],

                list: [],
                cols: this.$help.generalCols([
                    {fieldName: 'year', name: '年份', width: 60,listClass: 'tc'},
                    {fieldName: 'amount', name: `销售额（${this.$variable.unit}）`, width: 100,listClass: 'tc'},
                    {fieldName: 'rate', name: '增长率(%)', width: 100,listClass: 'tc'},
                ]),

            }
        },
        mounted() {
            // this.remoteMethod()
            this.initData()
        },
        methods: {
            initData() {
                this.loading = true
                this.$api.post('overview/saleYear/sumYearAmountAndCalculateRate', {drugsNature: this.drugsNature}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.code === 'A0004') {
                            this.error = res.code
                        }
                        if (res.success) {
                            this.list = res.data.map(item => {
                                item.rate = this.$help.parseFloat100(item.rate)
                                return item
                            })
                            this.$help.renderBar(this.nameKey, res.data, 'year')
                        } 
                    }).finally(()=>{
                        this.loading = false
                    })
            },
            exportInfo(txt = '确定导出吗', query = {}) {
                if(this.error === 'A0004') return false
                this.$store.commit('confirm', {
                    content: txt,
                    callback:(next, back) => {
                        this.$api.get('overview/saleYear/exportSumYearAmountAndCalculateRate', this.$help.joinObj({drugsNature: this.drugsNature}, query))
                            .then(res => {
                                if (!res.success && res.code !== 'N0001') {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === 'N0001') {
                                    next()
                                    this.exportInfo(res.message, {force: true})
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            },

            remoteMethod(keyword) {
                this.selectLoading = true
                this.$api.get('setting/aimDrugsSaleYear/findAimDrugsNature', {keyword: keyword})
                    .then(res => {
                        this.selectList = []
                        if(res.data && res.data.length !== 0) {
                            res.data.forEach(ele=>{
                                this.selectList.push({
                                    id: ele,
                                    name: ele 
                                })
                            })
                        }
                        this.selectList.unshift({
                            id: '',
                            name: '全部'
                        })
                    })
                    .finally(() => {
                        this.selectLoading = false
                    })
            },
        }
    }
</script>